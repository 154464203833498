
<div class="file-environment-panel">
  <div
    v-for="(item, index) in mountedConfigList"
    :key="`file-env-${index}`"
    class="grid grid-cols-4 gap-x-2"
  >
    <v-autocomplete
      v-model="item.mountedType"
      :items="mountedTypeOptions"
      label="* Mount Type"
      small-chips
      placeholder="Select Mount Type"
      class="app-g-autocomplete-v2"
      outlined
      dense
      :rules="[rules.required]"
      @change="
        () => {
          item.configType = '';
          item.name = '';
        }
      "
    />
    <v-autocomplete
      v-model="item.configType"
      :items="genConfigType(item.mountedType)"
      label="* Config Type"
      small-chips
      placeholder="Select Config Type"
      class="app-g-autocomplete-v2"
      outlined
      dense
      :rules="[item.mountedType ? rules.required : true]"
      @change="
        () => {
          item.name = '';
        }
      "
    />
    <v-autocomplete
      v-model="item.name"
      :items="genName(item.mountedType, item.configType)"
      label="* Resource Name"
      small-chips
      placeholder="Select Resource Name"
      class="app-g-autocomplete-v2"
      outlined
      dense
      :rules="[item.mountedType ? rules.required : true]"
    />

    <div class="flex w-3">
      <v-icon class="h-6 mt-1" @click="handleRemoveClick(index)">
        mdi-close
      </v-icon>
    </div>
  </div>
  <Button class="add-button" size="md" @click="handleAddClick">
    <v-icon>mdi-plus</v-icon>
    ADD File/Environment value mount
  </Button>
</div>
