<template>
  <div class="p-0 m-0">
    <div class="header">
      <div class="left-header">
        <img
          src="~@/assets/img/arrow-back.svg"
          alt="SVG Icon"
          :style="{ cursor: 'pointer' }"
          @click="goBack"
        />
        <span>{{ mode === "EDIT" ? "Edit RDE" : "Create new RDE" }}</span>
      </div>

      <div class="right-header">
        <div v-if="adminAuth && mode === 'CREATE'" class="switch-v2-group">
          <Sw
            v-model="values.showCreateUser"
            inset
            appendClass="switch-v2"
          ></Sw>
          <span class="text-switch">Create User/Group RDE</span>
        </div>
        <Button :size="'md'" @click="goBack">Cancel</Button>
        <Button :type="'submit'" color="primary" :size="'md'" text fill>
          Apply
        </Button>
      </div>
    </div>
    <div class="rde-section">
      <div class="grid grid-cols-2">
        <Input
          v-model="values.appName"
          label="* RDE Name"
          appendClass="input-text-field-v2"
          :rules="[rules.required, rules.name]"
          :disabled="mode === 'EDIT'"
        />
        <Input
          v-model="values.displayName"
          label="* Display Name"
          appendClass="input-text-field-v2"
          :rules="[rules.required]"
        />
      </div>

      <Input
        v-model="values.description"
        label="Description"
        appendClass="input-text-field-v2"
      />
      <Select
        v-model="values.namespace"
        :items="namespace_items"
        :rules="[rules.required]"
        :disabled="mode === 'EDIT'"
        label="* Namespace"
        appendClass="select-v2"
        :solo="false"
      ></Select>

      <!-- <NumberInput
        v-model="values.replicas"
        appendClass="input-text-field-v2"
        label="* Replicas"
        :format="'none'"
      /> -->
      <div class="grid grid-cols-3">
        <InfraSelect
          :infraBaselineList="infraBaselineList"
          :values="values"
          :onChangeInfra="onChangeInfraRDE"
          :defaultValue="defaultValueInfra"
        />
        <NumberInput
          v-model="values.diskSize.disk"
          label="Disk - GiB"
          :format="'none'"
          appendClass="input-text-field-v2"
          :rules="[rules.required, maxDiskValidate]"
          :disabled="mode === 'EDIT'"
          :max="50"
        />
        <Select
          v-model="values.diskSize.type"
          :items="storage_type_items"
          :rules="[rules.required]"
          label="*Storage Type"
          appendClass="select-v2"
          :solo="false"
        ></Select>
      </div>
      <v-autocomplete
        v-model="values.tolerations"
        :items="node_group_items"
        item-text="value"
        :item-value="mapValueNodeGroup"
        label="* Node Taint"
        small-chips
        placeholder="Select Node Taint"
        class="app-g-autocomplete-v2"
        clearable
        deletable-chips
        multiple
        outlined
        dense
        :rules="[rules.required]"
      />
    </div>
    <div v-if="values.serviceTypes" class="rde-section">
      <div class="rde-title">Service Type</div>
      <div class="service-type">
        <div class="switch-v2-group">
          <Sw
            :value="values.serviceTypes.includes('vscode')"
            inset
            appendClass="switch-v2"
            @input="
              (value) =>
                setServiceTypes({ setValue, values, name: 'vscode', value })
            "
          ></Sw>
          <span class="text-switch">Visual Studio Code</span>
        </div>
        <div class="switch-v2-group">
          <Sw
            :value="values.serviceTypes.includes('webssh')"
            inset
            appendClass="switch-v2"
            @input="
              (value) =>
                setServiceTypes({ setValue, values, name: 'webssh', value })
            "
          ></Sw>
          <span class="text-switch">SSH</span>
        </div>
        <div class="switch-v2-group">
          <Sw
            :value="values.serviceTypes.includes('notebook')"
            inset
            appendClass="switch-v2"
            @input="
              (value) =>
                setServiceTypes({
                  setValue,
                  values,
                  name: 'notebook',
                  value,
                })
            "
          ></Sw>
          <span class="text-switch">Jupyter</span>
        </div>
      </div>
      <div v-if="!values.serviceTypes.length" class="text-xs text-error h-5">
        Require at least one service.
      </div>
    </div>

    <div class="rde-section">
      <div class="rde-title">Port List</div>
      <AddPortInputListV2
        :portList="values.portList"
        @change="
          (newPorts) => {
            setValue('portList', newPorts);
          }
        "
      />
    </div>

    <div
      v-if="values.serviceTypes && values.serviceTypes.includes('vscode')"
      class="rde-section"
    >
      <div class="rde-title">Visual Studio Code Setting</div>
      <VSCodeInput
        :values="values"
        :set-value="setValue"
        :installPackages="installPackages"
      />
      <InfraSizeInput
        serviceType="vscode"
        :values="values"
        :requirementInfraResource="guideInfraResource?.requirements"
        :validate="validate"
      />
    </div>

    <div
      v-if="values.serviceTypes && values.serviceTypes.includes('webssh')"
      class="rde-section"
    >
      <div class="rde-title">Web SSH Setting</div>
      <WebSSHInput :values="values" :adminAuth="adminAuth" />
      <InfraSizeInput
        serviceType="webssh"
        :values="values"
        :requirementInfraResource="guideInfraResource?.requirements"
        :validate="validate"
      />
    </div>

    <div
      v-if="values.serviceTypes && values.serviceTypes.includes('notebook')"
      class="rde-section"
    >
      <div class="rde-title">Jupyter Setting</div>
      <InfraSizeInput
        serviceType="notebook"
        :values="values"
        :requirementInfraResource="guideInfraResource?.requirements"
        :validate="validate"
      />
    </div>

    <div v-if="values.showCreateUser" class="rde-section">
      <div class="rde-title">Create Multiple RDE</div>
      <UserInput :values="values" :setValue="setValue" :workspace="workspace" />
    </div>
  </div>
</template>

<script>
import { RulesMixin } from "@/mixins/RulesMixin";
import { RequestMixin } from "@/mixins/RequestMixin";

import Button from "@/components/atoms/Button/Button.vue";
import Input from "@/components/atoms/Input/Input.vue";
import Sw from "@/components/atoms/Sw/Sw.vue";
import AddPortInputListV2 from "./AddPortInputListV2.vue";
import VSCodeInput from "./VSCodeInput/index.vue";
import { isEmpty, uniq } from "lodash";
import InfraSizeInput from "./InfraSizeInput.vue";
import WebSSHInput from "./WebSSHInput.vue";
import UserInput from "./UserInput.vue";
import {
  getInfraBaseline,
  getInfraByWorkspaceId,
} from "@/service/apis/workspaceApis";
import InfraSelect from "./InfraSelect.vue";
import Select from "@/components/atoms/Select/Select.vue";
import NumberInput from "@/components/atoms/NumberInput/NumberInput.vue";
import { ProfileAuthMixin } from "@/mixins/ProfileAuthMixin";

export default {
  props: {
    values: {
      type: Object,
      default: () => {},
    },
    defaultValues: {
      type: Object,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: () => undefined,
    },
    workspace: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "CREATE",
    },
    adminAuth: {
      type: Boolean,
      default: false,
    },
    storage_type_items: {
      type: Array,
      default: () => [],
    },
    validate: {
      type: Function,
      default: () => undefined,
    },
    installPackages: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Sw,
    Button,
    Input,
    AddPortInputListV2,
    VSCodeInput,
    InfraSizeInput,
    WebSSHInput,
    UserInput,
    InfraSelect,
    Select,
    NumberInput,
  },
  mixins: [RulesMixin, RequestMixin, ProfileAuthMixin],
  data: () => ({
    namespace_items: [],
    infraBaselineList: [],
    node_group_items: [],
  }),
  computed: {
    defaultValueInfra() {
      if (
        this.mode === "CREATE" &&
        this.workspace?.defaultSettings?.resourceType
      )
        return this.workspace.defaultSettings.resourceType;

      return this.defaultValues?.resourceSize?.infraId;
    },
    guideInfraResource() {
      if (this.values?.resourceSize?.infraId && this.infraBaselineList?.length)
        return this.infraBaselineList.find(
          (i) => `${i.id}` === `${this.values.resourceSize.infraId}`,
        )?.resourceGuides;

      return {};
    },
  },
  watch: {
    workspace: {
      immediate: true,
      handler(v) {
        this.getDataFromWS(v);
        this.setDefaultRDECreate();
      },
    },
    "defaultValues.tolerations": {
      immediate: true,
      handler() {
        this.setDefaultGroupNode();
      },
    },
    node_group_items: {
      immediate: true,
      handler() {
        this.setDefaultGroupNode();
        this.setDefaultRDECreate();
      },
    },
    infraBaselineList: {
      immediate: true,
      handler() {
        this.setDefaultRDECreate();
      },
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    setServiceTypes({ setValue, values, name, value }) {
      let serviceTypes = [];
      if (value) {
        serviceTypes = uniq([...values.serviceTypes, name]) || [];
      } else {
        serviceTypes = values.serviceTypes.filter((i) => i !== name) || [];
      }
      setValue("serviceTypes", serviceTypes);

      this.allocateResourceDefault(null, serviceTypes);

      setTimeout(() => {
        this.validate();
      }, 200);
    },
    setDefaultRDECreate() {
      // support auto fill data (namespace, resourceSize, diskSize.type, tolerations ) from workspace
      if (
        this.mode === "CREATE" &&
        this.workspace?.defaultSettings &&
        this.infraBaselineList?.length &&
        this.node_group_items?.length
      ) {
        if (this.workspace?.defaultSettings.namespace) {
          this.setValue("namespace", this.workspace?.defaultSettings.namespace);
        }

        if (this.workspace?.defaultSettings.resourceType) {
          const selectedObj = this.infraBaselineList.find(
            (i) =>
              Number(i.id) ===
              Number(this.workspace?.defaultSettings.resourceType),
          );

          this.onChangeInfraRDE(selectedObj);
        }

        if (this.workspace?.defaultSettings.nodeTaint) {
          const nodeTaints =
            this.workspace?.defaultSettings.nodeTaint.split(",");

          const values = this.node_group_items?.filter((item) =>
            nodeTaints.includes(item?.value),
          );

          this.setValue("tolerations", values);
        }

        if (this.workspace?.defaultSettings.storageType) {
          this.setValue(
            "diskSize.type",
            this.workspace?.defaultSettings.storageType,
          );
        }
      }
    },
    async getDataFromWS(ws) {
      if (!isEmpty(ws)) {
        this.namespace_items = ws?.namespaces.split(",") || [];
        this.node_group_items = ws?.ideWorkspaceTaintList || [];
      }
    },
    async fetchInfraBaseline() {
      const workspaceId = this.$route.params.workspaceId;
      (this.adminAuth
        ? getInfraBaseline()
        : getInfraByWorkspaceId({ workspaceId: workspaceId })
      ).then((res) => {
        this.infraBaselineList = res.data;
      });
    },
    onChangeInfraRDE(obj) {
      const selectedObj = {
        infraId: obj?.id || "",
        cpu: obj?.limitCpu || "",
        memory: obj?.limitMemory || "",
      };

      this.setValue("resourceSize", selectedObj);
      this.allocateResourceDefault(selectedObj);

      setTimeout(() => {
        this.validate();
      }, 200);
    },
    maxDiskValidate(v) {
      if (v > 50) "Disk exceeds 50";
      return true;
    },
    allocateResourceDefault(selectedResourceSizeParams, serviceTypesParams) {
      const selectedId = selectedResourceSizeParams?.infraId;
      const resourceGuides = selectedId
        ? this.infraBaselineList.find((i) => `${i.id}` === `${selectedId}`)
            ?.resourceGuides
        : this.guideInfraResource;

      const serviceTypes = serviceTypesParams || this.values.serviceTypes;

      if (resourceGuides?.recommends && serviceTypes?.length) {
        serviceTypes.forEach((key) => {
          const selectedRecommend = resourceGuides?.recommends?.find(
            (r) => r.serviceType === key,
          );
          if (selectedRecommend) {
            this.setValue(`${key}.resourceSize`, {
              cpu: parseInt(Number(selectedRecommend?.cpu?.value), 10),
              memory: parseInt(Number(selectedRecommend?.memory?.value), 10),
            });
          }
        });
      }
    },
    mapValueNodeGroup(item) {
      return JSON.stringify({
        id: item.id,
        key: item?.key,
        operator: "Equal",
        value: item?.value,
        effect: item?.effect,
      });
    },
    setDefaultGroupNode() {
      if (
        this.node_group_items.length &&
        this.defaultValues?.tolerations?.length
      ) {
        const selectedItems =
          this.node_group_items
            ?.filter((item) => {
              return this.defaultValues?.tolerations.some(
                (t) =>
                  t?.key === item?.key &&
                  t?.value === item?.value &&
                  t?.effect === item?.effect,
              );
            })
            ?.map((item) => this.mapValueNodeGroup(item)) || [];
        this.setValue("tolerations", selectedItems);
      }
    },
  },
  beforeMount() {
    this.fetchInfraBaseline(this.isAdmin);
  },
};
</script>

<style lang="scss">
.rde-form {
  .input-text-field-v2 {
    padding: 2px;
    & .v-label {
      top: 10px !important;
    }
    .v-label--active {
      top: 12px !important;
    }
    fieldset {
      background: white !important;
    }
    .v-text-field__details {
      min-height: unset;
      height: fit-content;
      .v-messages {
        min-height: unset;
      }
    }
  }
  .select-v2 {
    padding: 2px;
    & .v-label {
      top: 10px !important;
    }
    .v-label--active {
      top: 12px !important;
    }
    fieldset {
      background: white !important;
    }
    .v-text-field__details {
      min-height: unset;
      height: fit-content;
      .v-messages {
        min-height: unset;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: #fff;

    .left-header {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #000000de;
        padding-left: 10px;
      }
    }

    .right-header {
      display: flex;
      align-items: center;
      gap: 10px;
      .text-switch {
        font-size: 16px;
        font-weight: 400;
        color: #00000099;
      }
    }
  }

  .general-input {
    background-color: white;
    margin: 5px;
    padding: 10px;
  }

  .service-type {
    display: flex;
    background: white;
    align-items: center;
    gap: 20px;
    margin-left: 5px;
  }

  .switch-v2-group {
    display: flex;
    align-items: center;

    .switch-v2 {
      .v-input__slot {
        margin-bottom: unset;
        height: 24px;
      }
      .v-messages {
        display: none;
      }
      .v-input--selection-controls__input {
        transform: scale(0.8);
      }
    }
    .text-switch {
      font-size: 14px;
      font-weight: 400;
      color: #00000099;
    }
  }

  .rde-section {
    padding: 10px;
    margin: 5px;
    background-color: white;
    border-radius: 2px;
    .rde-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #343a40;
      background: #3399ff1a;
      padding: 2px 8px;
      margin-bottom: 5px;
      width: fit-content;
      border-radius: 4px;
    }
  }

  .horizontal-line {
    background: #949494;
    height: 1px;
    border: none;
    margin: 10px 0px;
  }

  .add-button {
    border: none;
    background: #f5f5f5;
    font-size: 12px;
    font-weight: 700;
  }

  .rde-autocomplete {
    margin-top: 2px;
    fieldset {
      background-color: white !important;
    }
    .v-select__slot {
      min-height: 35px;
    }
    .v-input__slot {
      margin: 0px;
      margin-bottom: 4px !important;
    }

    &.v-text-field--outlined {
      .v-select__slot {
        .v-label {
          top: 11px !important;
          font-size: 14px;
          z-index: 1;
          background-color: #fff;
        }
      }
    }
    .v-select__selections {
      overflow: auto;
      max-height: unset;
    }
    .v-text-field__details {
      min-height: unset;
      height: fit-content;
      .v-messages {
        min-height: unset;
      }
    }
  }
}
</style>
