
<v-data-table
  :headers="headers"
  :items="data"
  hide-default-footer
  dense
  disable-pagination
  class="table__wrapper_resource_rde"
>
  <template v-slot:[`item.serviceType`]="{ item }">
    <td class="header-table">
      {{ item.serviceType }}
    </td>
  </template>
  <template v-slot:[`item.cpu`]="{ item }">
    <td>
      <div class="resource-box">
        <span
          class="usage-percentage"
          :style="getUsageStyle(item?.cpu?.usage, item?.cpu?.limit)"
        ></span>
        <label class="content text-start">
          {{ `${item?.cpu?.usage}/${item?.cpu?.limit} mi` }}
        </label>
      </div>
    </td>
  </template>
  <template v-slot:[`item.memory`]="{ item }">
    <td>
      <div class="resource-box">
        <span
          class="usage-percentage"
          :style="getUsageStyle(item?.memory?.usage, item?.memory?.limit)"
        ></span>
        <label class="content text-start">
          {{ `${item?.memory?.usage}/${item?.memory?.limit} MiB` }}
        </label>
      </div>
    </td>
  </template>
</v-data-table>
