
<div class="w-full grid grid-cols-10 grid-flow-col">
  <div class="col-span-2 flex flex-col justify-center">
    <div class="add-on-info">
      {{ item?.workspace?.displayName || item?.workspace?.name }}
    </div>
    <div class="">
      <span class="workspace-name pr-1">{{
        item.displayName || item.name
      }}</span>
      <Tooltip
        v-if="item?.description"
        :text="item?.description"
        position="right"
        targetSelf
        class="pr-2"
      >
        <v-icon size="medium"> mdi-information-outline </v-icon>
      </Tooltip>
      <ItemStatus :status="podStatus" :item="item" :tooltip="tooltipMsg" />
    </div>
  </div>
  <div class="col-span-2 flex flex-col justify-center">
    <div class="add-on-info padding">
      Namespace: <span>{{ item?.namespace }}</span>
    </div>
    <div class="add-on-info padding mt-1">
      Username: <span class="add-on-detail">{{ item?.userName }}</span>
    </div>
  </div>
  <div class="wp-info col-span-3 grid grid-cols-3 gap-3">
    <div class="col-span-1 flex flex-col justify-center">
      <div class="flex flex-row gap-1">
        <SvgIcon iconName="cpu" size="sm" color="#3399FF" />
        <label>CPU</label>
      </div>
      <div class="resource-box">
        <span
          class="usage-percentage"
          :style="getUsageStyle(item.currentUsage?.cpu, item?.cpu)"
        ></span>
        <label class="content">
          {{ $helper.viewCpuData(item?.currentUsage?.cpu || 0, item?.cpu) }}
        </label>
      </div>
    </div>
    <div class="col-span-1 flex flex-col justify-center">
      <div class="flex flex-row gap-1">
        <SvgIcon iconName="memory" size="sm" color="#3399FF" />
        <label>Memory</label>
      </div>
      <div class="resource-box">
        <span
          class="usage-percentage"
          :style="getUsageStyle(item.currentUsage?.memory, item?.memory)"
        ></span>
        <label class="content">
          {{
            $helper.viewMemoryData(
              item?.currentUsage?.memory || 0,
              item?.memory,
            )
          }}
        </label>
      </div>
    </div>
    <div class="col-span-1 flex flex-col justify-center">
      <div class="flex flex-row gap-1">
        <SvgIcon iconName="disk" size="sm" color="#3399FF" />
        <label>Disk</label>
      </div>
      <div class="resource-box">
        <span
          class="usage-percentage"
          :style="getUsageStyle(item.currentUsage?.disk, item?.disk)"
        ></span>
        <label class="content">
          {{
            `${item.currentUsage ? item.currentUsage?.disk + "/" : ""}${
              item?.disk
            }`
          }}
          {{ item.diskUnit }}
        </label>
      </div>
    </div>
  </div>
  <div class="col-span-3 w-full flex flex-col justify-center">
    <div class="flex flex-row gap-2 p-2 w-full flex-wrap justify-end">
      <div class="icon-resource">
        <v-icon
          title="view resource detail"
          @click="() => viewResourceRDE(item)"
        >
          mdi-note-text-outline
        </v-icon>
      </div>
      <ButtonStatus
        :available="item.serviceTypes.includes('vscode')"
        :disabled="podStatus !== POD_STATUS.running"
        btType="vscode"
        :status="listServiceStatus.vscodeserver"
        :to="`${buildURL(item)}/vscode`"
        :podStatus="podStatus"
      />
      <ButtonStatus
        :available="item.serviceTypes.includes('webssh')"
        :disabled="podStatus !== POD_STATUS.running"
        btType="ssh"
        :status="listServiceStatus.sshserver"
        :to="`${buildURL(item)}/ssh`"
        :podStatus="podStatus"
      />
      <ButtonStatus
        :available="item.serviceTypes.includes('notebook')"
        :disabled="podStatus !== POD_STATUS.running"
        btType="jupyter"
        :status="listServiceStatus.jupyter"
        :to="`${buildURL(item)}/notebook`"
        :podStatus="podStatus"
      />
    </div>
  </div>
  <div class="menu-btn flex flex-col justify-center">
    <div class="text-right pr-3 flex flex-row">
      <IconButton
        class="border-none"
        size="md"
        iconName="mdi-cached"
        color="#87888C"
        tooltip="Update Status"
        @click="() => reloadRdeStatus(item.id)"
      ></IconButton>
      <v-menu offset-y transition="scale-transition">
        <template v-slot:activator="{ on }">
          <IconButton
            class="border-none"
            size="md"
            iconName="mdi-dots-horizontal"
            color="#87888C"
            tooltip="Actions"
            v-on="on"
          ></IconButton>
        </template>
        <v-list>
          <v-list-item
            v-for="(menuItem, i) in Menus"
            :key="i"
            class="list-rde-menu-item py-1"
            :disabled="menuItem.disabled"
            @click="menuItem.action(item)"
          >
            <v-list-item-title>
              <div class="flex flex-row gap-1 items-center">
                <v-icon
                  v-if="menuItem.icon"
                  :color="menuItem.disabled ? '#00000060' : menuItem.color"
                >
                  {{ menuItem.icon }}
                </v-icon>
                <ImageIcon
                  v-if="menuItem.svgIcon"
                  :iconName="menuItem.svgIcon"
                />
                {{ menuItem.title }}
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</div>
