<template>
  <v-dialog v-model="show" persistent scrollable :max-width="maxWidth">
    <v-card class="p-4">
      <template v-if="!!title">
        <v-card-title>
          <v-icon color="warning">mdi-alert-circle-outline </v-icon>
          <span class="dialog-title">{{ title }}</span>
          <v-spacer />
          <v-btn icon color="grey" @click="closePopup">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </template>
      <v-card-text class="pl-10 pt-0">
        <span v-if="message" class="dialog-message"> {{ message }} </span>
        <component :is="content" v-else-if="!!content" v-bind="contentProps" />
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <fragment v-if="actions">
          <v-btn
            v-for="(action, index) in actions"
            :key="index"
            class="no-uppercase"
            :color="action.color"
            :fill="action.fill"
            @click="() => action.onClick(this.closePopup)"
          >
            {{ action.text }}
          </v-btn>
        </fragment>
        <fragment v-else>
          <v-btn
            class="no-uppercase"
            outlined
            color="primary"
            @click="closePopup"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn class="no-uppercase" fill color="primary" @click="onClickYes">
            {{ yesText }}
          </v-btn>
        </fragment>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    content: {
      type: [Object, String],
    },
    maxWidth: {
      type: [String, Number],
      default: "400px",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    yesText: {
      type: String,
      default: "Yes",
    },
    hanldeClickYes: {
      type: Function,
      default: () => {},
    },
    actions: {
      type: Array,
    },
  },
  methods: {
    closePopup() {
      CommonUIControl.HideModalDialogConfirmV2();
    },
    onClickYes() {
      this.hanldeClickYes();
      this.closePopup();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
  margin-left: 15px;
}

.dialog-message {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary);
}
</style>
