<template>
  <div class="p-0 m-0">
    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">Register Installation Packages</p>
      <div class="grid grid-cols-4 mt-0">
        <v-autocomplete
          v-model="values.installPackages.javaVersion"
          :items="javaVersions"
          label="Java Version"
          small-chips
          placeholder="Select Java Version"
          class="app-g-autocomplete-v2"
          outlined
          dense
        />
        <v-autocomplete
          v-model="values.installPackages.nodeVersion"
          :items="nodeVersions"
          label="Node Version"
          small-chips
          placeholder="Select Node Version"
          class="app-g-autocomplete-v2"
          outlined
          dense
        />
        <v-autocomplete
          v-model="values.installPackages.aptPackages"
          :items="linuxPackages"
          label="Linux Packages"
          small-chips
          placeholder="Select Linux Packages"
          class="app-g-autocomplete-v2"
          clearable
          deletable-chips
          outlined
          dense
          multiple
        />
        <v-autocomplete
          v-model="values.installPackages.pipPackages"
          :items="pythonPackages"
          label="Python Packages"
          small-chips
          placeholder="Select Python Packages"
          class="app-g-autocomplete-v2"
          clearable
          deletable-chips
          outlined
          dense
          multiple
        />
      </div>
    </div>
    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">Register Pre-start scripts</p>
      <div class="grid grid-cols-4 mt-0">
        <v-autocomplete
          v-model="values.installPackages.preflightConfig.configType"
          :items="preflightScripts"
          small-chips
          label="Select preflight config"
          placeholder="Select preflight config"
          class="app-g-autocomplete-v2"
          outlined
          dense
          @change="onChangePreflightConfigType"
        />
        <v-autocomplete
          v-model="values.installPackages.preflightConfig.name"
          :items="preflightConfigNames"
          small-chips
          placeholder="Resource Name"
          label="Select Resource Name"
          class="app-g-autocomplete-v2"
          outlined
          dense
          :rules="[
            values.installPackages.preflightConfig.configType
              ? rules.required
              : true,
          ]"
          @change="onChangePreflightResourceName"
        />
      </div>
    </div>
    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">File and Environment Value Mount</p>
      <FileEnvironment
        :mountedConfigList="values.installPackages.mountedConfigList"
        :installPackages="installPackages"
        @change="setValue('installPackages.mountedConfigList', $event)"
      />
    </div>

    <div class="border p-2 mb-2 rounded-md border-gray-300">
      <p class="label-mini-section">Register Shared Storage</p>
      <StorageShare
        :mountedFSList="values.installPackages.mountedFSList"
        :installPackages="installPackages"
        @change="setValue('installPackages.mountedFSList', $event)"
      />
    </div>
  </div>
</template>

<script>
import { RulesMixin } from "@/mixins/RulesMixin";

export default {
  props: {
    values: { type: Object },
    setValue: { type: Function, default: () => undefined },
    installPackages: { type: Object, default: () => ({}) },
  },
  mixins: [RulesMixin],
  data: () => ({
    javaVersions: [],
    nodeVersions: [],
    linuxPackages: [],
    pythonPackages: [],
    preflightScripts: [],
    preflightConfigNames: [],
  }),
  watch: {
    installPackages: {
      handler() {
        this.javaVersions = this.installPackages?.javaVersions || [];
        this.nodeVersions = this.installPackages?.nodeVersions || [];
        this.pythonPackages = this.installPackages?.pythonPackages || [];
        this.linuxPackages = this.installPackages?.linuxPackages || [];

        this.preflightScripts =
          Object.keys(this.installPackages?.preflightScripts || {}) || [];
      },
      immediate: true,
    },
  },
  methods: {
    onChangePreflightConfigType: function (value) {
      this.setValue(
        "installPackages.preflightConfig.name",
        this.installPackages?.preflightScripts?.[value]?.[0] || "",
      );
      this.preflightConfigNames =
        this.installPackages?.preflightScripts?.[value];
    },
    onChangePreflightResourceName: function (value) {
      this.setValue("installPackages.preflightConfig.name", value || "");
    },
  },
};
</script>

<style lang="scss" scoped>
.label-mini-section {
  font-weight: 500;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 5px;
}
</style>
