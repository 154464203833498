<template>
  <!-- <div v-if="isShow"> -->
  <Doughnut
    v-if="isShow"
    :data="finalData"
    :options="finalOptions"
    :plugins="finalPlugins"
  />
  <!-- </div> -->
</template>

<script lang="ts">
import { Doughnut } from "vue-chartjs";
import { isEmpty } from "lodash";
import { transformData, transformOptions, transformPlugins } from "./handler";

export default {
  name: "DoughChart",
  props: ["data", "options"],
  components: {
    Doughnut,
  },
  data() {
    return {};
  },
  computed: {
    isShow: function () {
      return !isEmpty(this.data);
    },
    finalData() {
      return transformData(this.data);
    },
    finalOptions() {
      // In next time, we can customize something before push into bar chart component

      return transformOptions(this.options);
    },
    finalPlugins() {
      return transformPlugins(this.plugins);
    },
  },
  mounted() {},
  updated() {},
};
</script>
