<template>
  <div v-if="defaultValues" class="p-0 m-0">
    <Form
      ref="form"
      :defaultValues="defaultValues"
      v-bind="$attrs"
      class="rde-form"
      :before="handleSubmitBefore"
      v-on="$listeners"
    >
      <template #default="{ values, setValue, validate }">
        <EditRDE
          :workspace="workspace"
          :installPackages="installPackages"
          :mode="mode"
          :adminAuth="adminAuth"
          :values="values"
          :setValue="setValue"
          :defaultValues="defaultValues"
          :storage_type_items="storage_type_items"
          :validate="validate"
        />
      </template>
    </Form>
  </div>
</template>
<script>
import Form from "@/components/atoms/Form/Form.vue";

import EditRDE from "@/components/newWorkspace/EditRDE/index.vue";
import { cloneDeep, get, isEmpty, set } from "lodash";
import { mapState } from "vuex";
import CommonUIControl from "@/helper/CommonUIControl";
import {
  addWorkspaceRde,
  getWorkspaceById,
  getWorkspaceRdeById,
  editWorkspaceRde,
  addWorkspaceRdeMulti,
  getStorageType,
  getInstallPackageByWorkspace,
} from "@/service/apis/workspaceApis";
import { RequestMixin } from "@/mixins/RequestMixin";

const defaultValues = {
  description: "",
  userName: "",
  namespace: "",
  useExtention: false,
  packageType: [],
  replicas: 1,
  //serviceTypes: ["vscode", "webssh", "notebook"],
  serviceTypes: ["vscode"],
  portList: [],
  resourceSize: {
    infraId: null,
    cpu: null,
    memory: null,
  },
  diskSize: {
    disk: 10,
    type: "block-storage",
  },
  vscode: {
    useGit: false,
    git: {
      id: "",
      repository: "",
      token: "",
      branch: "",
    },
    setResource: true,
    resourceSize: {
      cpu: "",
      memory: "",
    },
    useFiles: false,
    files: [],
  },
  webssh: {
    permission: {
      role: "",
      scope: "",
      serviceAccountName: "",
    },
    setResource: true,
    resourceSize: {
      cpu: "",
      memory: "",
    },
  },
  notebook: {
    setResource: true,
    resourceSize: {
      cpu: "",
      memory: "",
    },
  },
  rdeServiceType: {
    rdeType: "user",
    managedServices: [
      {
        name: "",
        wsName: "",
        appName: "",
      },
    ],
  },
  tolerations: [],
  selectedUsers: [],
  wsName: "",
  appName: "",
  displayName: "",
  useInstallPackages: false,
  installPackages: {
    aptPackages: "",
    pipPackages: "",
    javaVersion: "",
    nodeVersion: "",
    preflightConfig: {
      configType: "",
      name: "",
    },
    mountedConfigList: [],
    mountedFSList: [],
  },
};

export default {
  components: { EditRDE, Form },
  rules: [RequestMixin],
  data: () => ({
    mode: "CREATE",
    workspaceId: "",
    rdeId: "",
    workspace: {},
    rde: {},
    storage_type_items: [],
    installPackages: {},
  }),
  computed: {
    ...mapState({
      accountInfo: (state) => state.accountInfo,
      adminAuth: (state) => {
        return (
          ["administrator", "admin"].includes(state.accountInfo.username) ||
          state.accountInfo.auth === "ADMINISTRATOR"
        );
      },
    }),
    defaultValues() {
      if (this.mode === "CREATE") {
        // this is create page
        let newDefaultValues = cloneDeep(defaultValues);

        // set default for resource size
        newDefaultValues.vscode.resourceSize.cpu = 0;
        newDefaultValues.vscode.resourceSize.memory = 0;

        newDefaultValues.webssh.resourceSize.cpu = 0;
        newDefaultValues.webssh.resourceSize.memory = 0;

        newDefaultValues.notebook.resourceSize.cpu = 0;
        newDefaultValues.notebook.resourceSize.memory = 0;

        if (this.accountInfo?.username) {
          newDefaultValues.userName = this.accountInfo?.username;
          newDefaultValues.webssh.permission.serviceAccountName = `${this.accountInfo.username}-ide-account`;
        }
        return newDefaultValues;
      } else if (!isEmpty(this.rde)) {
        // this is edit page
        // do something if transform data is neccessary
        return this.transformDetailsRDE();
      }

      return null;
    },
  },
  methods: {
    async handleSubmitBefore(values) {
      const newValues = cloneDeep(values);
      const users = newValues.selectedUsers;
      delete newValues.selectedUsers;

      if (!newValues.serviceTypes.length) {
        CommonUIControl.ShowErrorToast(
          "You should have at least one type of service!",
        );
        return false;
      }

      if (!newValues.portList.length) {
        CommonUIControl.ShowErrorToast("You should have at least one port!");
        return false;
      }

      const usedVscode = newValues.serviceTypes.includes("vscode");

      if (!usedVscode) {
        newValues.vscode = defaultValues.vscode;
      } else {
        if (!newValues.vscode) {
          newValues.vscode = {};
        }

        if (!newValues.vscode.useGit) {
          if (!newValues.vscode.git) {
            newValues.vscode.git = {};
          }

          newValues.vscode.git.id = "";
          newValues.vscode.git.repository = "";
          newValues.vscode.git.branch = "";
          newValues.vscode.git.token = "";
        }
        if (!newValues.vscode.setResource) {
          newValues.vscode.resourceSize = {
            cpu: "",
            memory: "",
          };
        } else {
          newValues.vscode.resourceSize.cpu = `${newValues.vscode.resourceSize.cpu}m`;
          newValues.vscode.resourceSize.memory = `${newValues.vscode.resourceSize.memory}Mi`;
        }

        if (!newValues.vscode.useFiles) {
          newValues.vscode.files = [];
        }
      }

      const usedWebSsh = newValues.serviceTypes.includes("webssh");

      if (!usedWebSsh) {
        newValues.webssh = null;
      } else {
        if (!newValues.webssh) {
          newValues.webssh = {};
        }
        if (!newValues.webssh.permission) {
          newValues.webssh.permission = {};
        }

        if (!newValues.webssh.setResource) {
          newValues.webssh.resourceSize = {
            cpu: "",
            memory: "",
          };
        } else {
          newValues.webssh.resourceSize.cpu = `${newValues.webssh.resourceSize.cpu}m`;
          newValues.webssh.resourceSize.memory = `${newValues.webssh.resourceSize.memory}Mi`;
        }
      }
      const usedNotebook = newValues.serviceTypes.includes("notebook");

      if (!usedNotebook) {
        newValues.notebook = defaultValues.notebook;
      } else {
        if (!newValues.notebook) {
          newValues.notebook = {};
        }

        if (!newValues.notebook.setResource) {
          newValues.notebook.resourceSize = {
            cpu: "",
            memory: "",
          };
        } else {
          newValues.notebook.resourceSize.cpu = `${newValues.notebook.resourceSize.cpu}m`;
          newValues.notebook.resourceSize.memory = `${newValues.notebook.resourceSize.memory}Mi`;
        }
      }

      // map resource size for overall RDE
      newValues.resourceSize.cpu = `${newValues.resourceSize.cpu}m`;
      newValues.resourceSize.memory = `${newValues.resourceSize.memory}Mi`;
      newValues.diskSize.disk = `${newValues.diskSize.disk}Gi`;

      newValues.userName = newValues?.userName || this.accountInfo.username;
      newValues.wsName = this.workspace.name;

      newValues.rdeServiceType = {
        rdeType: "user",
        managedServices: [
          {
            name: "",
            wsName: "",
            appName: "",
          },
        ],
      };

      // newValues.rdeServiceType.rdeType = "user";
      // newValues.rdeServiceType.managedServices[0].name = newValues.userName;
      // newValues.rdeServiceType.managedServices[0].wsName = newValues.wsName;
      // newValues.rdeServiceType.managedServices[0].appName = newValues.appName;

      // currently set replicas fixed value 1
      newValues.replicas = 1;

      // map port list
      newValues.portList = newValues.portList
        ?.filter((p) => p?.name && p?.port)
        .map((port) => {
          return {
            name: port.name,
            protocol: port.protocol,
            port: port.port,
            targetPort: port.port,
          };
        });

      // map tolerations
      newValues.tolerations =
        newValues.tolerations?.map((item) =>
          typeof item === "string" ? JSON.parse(item || {}) : item,
        ) || [];

      // handle install packages
      if (!newValues.useInstallPackages) {
        delete newValues.installPackages;
      }

      delete newValues.useInstallPackages;

      let saveFunction;
      CommonUIControl.ShowUIProgress();

      if (
        this.mode === "CREATE" &&
        this.adminAuth &&
        newValues?.showCreateUser &&
        users?.length > 0
      ) {
        const dataList = users.map((user) => {
          let newUserValues = cloneDeep(newValues);
          newUserValues.userName = user;
          if (newUserValues?.webssh?.permission) {
            newUserValues.webssh.permission.serviceAccountName = `${user}-ide-account`;
          }
          delete newUserValues.showCreateUser;

          return newUserValues;
        });

        saveFunction = addWorkspaceRdeMulti({
          dataList,
          namespace: newValues.namespace,
          workspaceId: this.workspaceId,
          domainName: "localDomainRS",
        });
      } else if (this.mode === "CREATE") {
        delete newValues.showCreateUser;

        saveFunction = addWorkspaceRde({
          workspaceId: this.workspaceId,
          domainName: "localDomainRS",
          namespace: newValues.namespace,
          name: newValues.userName,
          ideConfigSpec: newValues,
        });
      } else {
        delete newValues.showCreateUser;

        saveFunction = editWorkspaceRde({
          rdeId: this.rdeId,
          ideConfigSpec: newValues,
        });
      }

      await saveFunction
        .then(() => {
          {
            this.$router.replace(
              `/newwp/list-rde/${this.workspaceId}${
                this.rdeId ? `/${this.rdeId}` : ""
              }`,
            );
            CommonUIControl.ShowSuccessToast(
              `${this.mode} RDE saved successfully!`,
              5000,
            );
          }
        })
        .catch((e) => {
          CommonUIControl.ShowErrorToast(e?.message);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });

      return false;
    },

    excludeUnitText(value) {
      const CHARACTERS_TO_REMOVE = ["m", "mi", "Gi", "Mi", "gi"];

      if (typeof value === "string") {
        CHARACTERS_TO_REMOVE.forEach((str) => {
          value = value.replace(new RegExp(str, "g"), "");
        });
        return value;
      }
      return value;
    },

    transformDetailsRDE() {
      const finalDetail = {
        ...defaultValues,
        ...this.rde,
        notebook: this.rde.notebook || defaultValues.notebook,
      };

      if (!finalDetail.vscode) {
        finalDetail.vscode = defaultValues.vscode;
      }

      if (!finalDetail.webssh) {
        finalDetail.webssh = defaultValues.webssh;
      }

      if (!finalDetail.notebook) {
        finalDetail.notebook = defaultValues.notebook;
      }

      // some infra variable is saved as 1000m, 2000Gi...
      // the below handles parsing them to number
      const infraVars = [
        "diskSize.disk",
        "resourceSize.cpu",
        "resourceSize.memory",
        "vscode.resourceSize.cpu",
        "vscode.resourceSize.memory",
        "webssh.resourceSize.cpu",
        "webssh.resourceSize.memory",
        "notebook.resourceSize.cpu",
        "notebook.resourceSize.memory",
      ];

      infraVars.forEach((key) => {
        if (get(finalDetail, key)) {
          set(finalDetail, key, this.excludeUnitText(get(finalDetail, key)));
        }
      });

      // this is a fix workaround when BE does not return the correct value (useGit, setResource)
      if (
        !finalDetail?.vscode?.useGit &&
        finalDetail?.vscode?.git?.repository
      ) {
        finalDetail.vscode.useGit = true;
      }

      if (
        !finalDetail?.webssh?.setResource &&
        finalDetail?.webssh?.resourceSize?.cpu
      ) {
        finalDetail.webssh.setResource = true;
      }

      if (finalDetail?.packageType?.length) {
        finalDetail.useExtention = true;
      }

      // map use install packages
      finalDetail.useInstallPackages = !!finalDetail?.installPackages;

      if (finalDetail?.installPackages) {
        finalDetail.installPackages.mountedFSList =
          finalDetail.installPackages.mountedFSList || [];

        finalDetail.installPackages.mountedConfigList =
          finalDetail.installPackages.mountedConfigList || [];

        finalDetail.installPackages.preflightConfig = finalDetail
          .installPackages.preflightConfig || {
          configType: "",
          name: "",
        };
      } else {
        finalDetail.installPackages = {
          aptPackages: "",
          pipPackages: "",
          javaVersion: "",
          nodeVersion: "",
          preflightConfig: {
            configType: "",
            name: "",
          },
          mountedConfigList: [],
          mountedFSList: [],
        };
      }

      return finalDetail;
    },
  },
  mounted() {
    const workspaceId = this.$route.params?.workspaceId;
    this.workspaceId = workspaceId;
    const rdeId = this.$route.params?.rdeId;
    this.rdeId = rdeId;

    this.mode = this.$route.fullPath.includes("create") ? "CREATE" : "EDIT";

    const requests = [];

    if (workspaceId) {
      requests.push(
        getWorkspaceById({ workspaceId })
          .then((res) => {
            this.workspace = res?.data?.data;
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch workspace failed");
          }),
      );
      requests.push(
        getInstallPackageByWorkspace(workspaceId)
          .then((res) => {
            this.installPackages = res?.data || {};
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch install package failed");
          }),
      );
    }
    if (this.mode === "EDIT" && rdeId) {
      requests.push(
        getWorkspaceRdeById({ rdeId })
          .then((res) => {
            this.rde = res?.data;
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch rde failed");
          }),
      );
    }

    if (workspaceId) {
      requests.push(
        getStorageType(workspaceId)
          .then((res) => {
            this.storage_type_items = res?.data?.data || [];
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch storage type failed");
          }),
      );
    }

    if (requests.length) {
      CommonUIControl.ShowUIProgress();

      Promise.allSettled(requests).finally(() => {
        CommonUIControl.HideUIProgress();
      });
    }
  },
};
</script>

<style lang="scss"></style>
