export const STORAGE_KEY = "LAST_SELECT_WORKSPACE_ID";
export const DURATION = 14;
export const USER_TYPE = {
  ADMINISTRATOR: "ADMINISTRATOR",
  DEVELOPER: "DEVELOPER",
};

export const ROLE_TYPE = {
  OWNER: "OWNER",
  MEMBER: "MEMBER",
};

export const POD_STATUS = {
  running: "Running",
  loading: "Loading",
  created: "Created",
  stop: "Stop",
  shutdown: "Shutdown",
  restart: "Restart",
  resume: "Resume",
  restarting: "Restarting",
  failed: "Failed",
  installing: "Installing",
  updating: "Updating",
};

export const UNIT_LABELS = {
  MiliCores: "mi",
  Core: "c",
  MebiByte: "MiB",
  KibiByte: "KiB",
  GibiByte: "Gi",
  Byte: "Byte",
};

export const DATE_FORMAT = {
  FULL: "YYYY-DD-MM HH:mm",
  SORT: "DD-MM HH:mm",
  HOUR_MINUTE_ONLY: "HH:mm",
  DAY_MONTH_ONLY: "DD-MMM",
};
