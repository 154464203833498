
<div class="storage-type-share-panel">
  <div
    v-for="(item, index) in mountedFSList"
    :key="`storage-type-${index}`"
    class="grid grid-cols-4 gap-x-2"
  >
    <v-autocomplete
      v-model="item.pvcName"
      :items="pvcNameOptions"
      label="* File Storage (PVC)"
      small-chips
      placeholder="Select File Storage (PVC)"
      class="app-g-autocomplete-v2"
      outlined
      dense
      :rules="[rules.required]"
    />
    <Input
      v-model="item.aliasName"
      placeholder="Enter Directory Name"
      :rules="[rules.required, rules.name, validateDuplicate]"
      label="* Directory Name"
      appendClass="input-text-field-v2 text-details"
    />
    <div class="flex w-3">
      <v-icon class="h-6 mt-1" @click="handleRemoveClick(index)">
        mdi-close
      </v-icon>
    </div>
  </div>
  <Button class="add-button" size="md" @click="handleAddClick">
    <v-icon>mdi-plus</v-icon>
    ADD Storage Share
  </Button>
</div>
