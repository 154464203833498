<template>
  <div
    class="w-auto relative inline-flex item-status-wrapper rounded-full"
    :style="{ 'background-color': bgColor }"
  >
    <div class="flex flex-row px-2">
      <div class="status-text pr-1" :style="{ color: btColor }">
        {{ this.status }}
      </div>
      <div class="flex flex-col justify-center">
        <v-progress-circular
          v-if="
            status == POD_STATUS.updating ||
            status == POD_STATUS.installing ||
            status == POD_STATUS.restarting ||
            status == POD_STATUS.restart ||
            status == POD_STATUS.resume ||
            status == POD_STATUS.loading
          "
          indeterminate
          :size="18"
          width="2"
          class="ml-2"
          :color="btColor"
        ></v-progress-circular>
        <Tooltip
          v-else-if="status != 'Running' && tooltip != ''"
          :text="tooltip"
          position="right"
          targetSelf
          :contentClass="
            status == POD_STATUS.failed ? 'p-2 text-sm inline-block' : ''
          "
        >
          <v-icon size="medium" :color="btColor"> mdi-alert-circle </v-icon>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { POD_STATUS } from "@/service/constants";

export default {
  props: {
    status: { type: String, default: "loading" },
    tooltip: { type: String },
    item: { type: Object },
  },
  data() {
    return {
      POD_STATUS,
    };
  },
  computed: {
    btStatus() {
      return POD_STATUS[this.status?.toLowerCase()] != null
        ? POD_STATUS[this.status?.toLowerCase()]
        : POD_STATUS.created;
    },
    btColor() {
      switch (this.status?.toLowerCase()) {
        case "running": {
          return "#00A738";
        }
        case "loading":
        case "installing":
        case "updating":
        case "pending":
        case "restart": {
          return "#DF6A08";
        }
        case "created": {
          return "#3399FF";
        }
        case "restarting":
        case "failed":
        case "stop": {
          return "#E55353";
        }
        case "shutdown": {
          return "#768192";
        }
        default:
          return "#00A738";
      }
    },
    bgColor() {
      switch (this.status?.toLowerCase()) {
        case "loading":
        case "installing":
        case "updating":
        case "pending":
        case "restart": {
          return this.$helper.hex2rgba("#DF6A08", 0.1);
        }
        case "created": {
          return this.$helper.hex2rgba("#3399FF", 0.1);
        }
        case "restarting":
        case "failed":
        case "stop": {
          return this.$helper.hex2rgba("#E55353", 0.1);
        }
        case "shutdown": {
          return this.$helper.hex2rgba("#768192", 0.1);
        }
        default:
          return this.$helper.hex2rgba("#00A738", 0.1);
      }
    },
  },
};
</script>

<style type="scss">
.item-status-wrapper {
  flex-basis: content;
  padding: 3px;
  .status-text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0015em;
    text-align: center;
  }
}
</style>
