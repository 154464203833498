
<div v-if="defaultValues" class="p-0 m-0">
  <Form
    ref="form"
    :defaultValues="defaultValues"
    v-bind="$attrs"
    class="rde-form"
    :before="handleSubmitBefore"
    v-on="$listeners"
  >
    <template #default="{ values, setValue, validate }">
      <EditRDE
        :workspace="workspace"
        :installPackages="installPackages"
        :mode="mode"
        :adminAuth="adminAuth"
        :values="values"
        :setValue="setValue"
        :defaultValues="defaultValues"
        :storage_type_items="storage_type_items"
        :validate="validate"
      />
    </template>
  </Form>
</div>
